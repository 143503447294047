<template>
  <div id="container">
    <div id="header_desktop">
      <img id="logo" src="@/assets/img/logo.png">
      <h1 id="title">ТРЕНАЖЕР ИНТУИЦИИ</h1>
    </div>

    <div id="header_mobile">

      <div v-on:click="show = !show" :class="menuMob" id="menu_gamburger">
          <div id="d1"></div>
          <div id="d2"></div>
          <div id="d3"></div>
      </div>

      <div v-if="show" class="menu_mob">
        <p>&nbsp;</p>
        <ul  v-on:click="show = !show" class="menu_ul">
          <li><router-link to="/teory">! Теория</router-link></li>
          <li><router-link to="/">Тренажер 1 из 2</router-link></li>
          <li><router-link to="/trenfive">Тренажер 1 из 5</router-link></li>
          <li><router-link to="/trenten">Тренажер 1 из 10</router-link></li>
          <li><router-link to="/trenthirty">Тренажер 1 из 30</router-link></li>
          <li><router-link to="/stat"> Статистика </router-link></li>
          <li v-if = "getUser.user_in === false"><router-link to="/reg"> Регистрация </router-link></li>
          <li v-if = "getUser.user_in === false"><router-link to="/login"> Вход </router-link></li>
          <li  class="in_out" v-if = "getUser.user_in === true" @click="out"> Выход </li>
        </ul> 
      </div>

      <img id="logo" src="@/assets/img/logo.png">

      <div class="mobile_head">

        <h1 id="title">ТРЕНАЖЕР ИНТУИЦИИ</h1>

        <div v-if = "getUser.user_in === true " >
          <img class="user_img" src="@/assets/img/user_yes.png">
          <p>{{getUser.email}}</p>
          <p class="in_out margin" @click="out"> (Выход) </p>
        </div>

        <div v-if = "getUser.user_in === false ">
          <img class="user_img" src="@/assets/img/user_no.png">
          <p>Гостевой режим</p>
          <p><router-link class="in_out margin_2" to="/login">  (Вход) </router-link></p>
          <p>&nbsp;</p>
        </div>

      </div> 

    </div>


    <div id="content">

      <div id="bar">

        <div v-if = "getUser.user_in === true " >
          <img class="user_img" src="@/assets/img/user_yes.png">
          <p>{{getUser.email}}</p>
          <p class="in_out" @click="out"> (Выход) </p>
        </div>

        <div v-if = "getUser.user_in === false ">
          <img class="user_img" src="@/assets/img/user_no.png">
          <p>Гостевой режим<router-link to="/login"><br> (Вход) </router-link></p>
        </div>

        <nav>
          <p class="menu_title"> Разделы </p>
          <ul class="menu_ul">
            <li><router-link to="/teory">! Теория</router-link></li>
            <li><router-link to="/">Тренажер 1 из 2</router-link></li>
            <li><router-link to="/trenfive">Тренажер 1 из 5</router-link></li>
            <li><router-link to="/trenten">Тренажер 1 из 10</router-link></li>
            <li><router-link to="/trenthirty">Тренажер 1 из 30</router-link></li>
            <li><router-link to="/stat"> Статистика </router-link></li>
            <li v-if = "getUser.user_in === false"><router-link to="/reg"> Регистрация </router-link></li>
            <li v-if = "getUser.user_in === false"><router-link to="/login"> Вход </router-link></li>
            <li  class="in_out" v-if = "getUser.user_in === true" @click="out"> Выход </li>
          </ul>
        </nav>
      </div>

      <div class="view">

        <div class="view_stat_desktop">

          <div class="view_img_desktop">
            <img v-if="(getCount.count_total < 50)" class="pro_ing_desktop" style="left: 250px" src="@/assets/img/pro_50.png">  
            <img v-else class="pro_ing_desktop" :style="{  left: getPercent*5 + 'px' }" src="@/assets/img/pro.png">  
            <img v-if="(getCount.count_total < 50)" class="mera_img_desktop" src="@/assets/img/mera_50.png">
            <img v-else class="mera_img_desktop" src="@/assets/img/mera.png">
          </div>

          <div class="view_percent_desktop"> 
            <div v-if="(getCount.count_total < 50)" class=""> &nbsp;&nbsp;(еще: {{(50 - getCount.count_total)}} попыток) </div>
            <div v-else class=""> &nbsp;&nbsp;({{getPercent}}%) </div>
          </div>

        </div>

        <div class="view_stat_mobile">

          <div class="view_img_mobile">
            <img v-if="(getCount.count_total < 50)" class="pro_ing_mobile" style="left: 150px" src="@/assets/img/pro_50.png">  
            <img v-else class="pro_ing_mobile" :style="{  left: getPercent*3 + 'px' }" src="@/assets/img/pro.png">  
            <img v-if="(getCount.count_total < 50)" class="mera_img_mobile" src="@/assets/img/mera_50.png">
            <img v-else class="mera_img_mobile" src="@/assets/img/mera.png">
          </div>

          <div class="view_percent_mobile"> 
            <div v-if="(getCount.count_total < 50)" class=""> &nbsp;&nbsp;(еще: {{(50 - getCount.count_total)}} попыток) </div>
            <div v-else class=""> &nbsp;&nbsp;({{getPercent}}%) </div>
          </div>

        </div>

        <div v-if="(getCount.count_total >= 50 && getUser.user_in == true)" @click = "saveTren" class="save_yes">Сохранить тренировку</div>
        <div v-else class="save_no">Сохранить тренировку</div>

        <router-view/>

      </div>

    </div>




  </div> 
  <p>&nbsp;</p>
  <footer><a href="https://www.arboreal.ru/">&copy; Сергей Щербаков 2018 - {{getYears}}</a></footer>  

</template>


        
<script>

import axios from 'axios';

export default {
             
            data() {
              return {
                  show: false,
                }
              },
              
            computed: {

              menuMob(){
                  if(this.show)return  "active"
                  else return  null
                },

              getCount() {
                return this.$store.getters.getCount},

              getUser() {
                return this.$store.getters.getUser}, 
            
              getPercent() {
                let pro = Math.trunc(this.getCount.count_all_true / this.getCount.count_total * 100);
                if(pro < 100 ) {
                  this.$store.dispatch('setPro', pro)
                  return pro; }
                else {return 100}
                },

              getYears() {
                  let currentDate = new Date();
                  let currentYear = currentDate.getFullYear();
                  return currentYear;
                },
            },

            mounted() {
              const qs = require('qs');
              axios
                .post('remember.php', qs.stringify({ 'email': this.st  }))
                .then(response => (this.logi = response.data))
                .then(setTimeout(()=>{this.allTre()}, 1000));
                },
                
            methods: {

              allTre(){
                    if(this.logi.in_salt === "true")
                    {
                      this.$store.dispatch('setIn');
                      this.$store.dispatch('setEmail', this.logi.mail);
                      this.login = true;
                    }
                },
                
                saveTren(){
                  let redir = () =>{this.$router.push({ path: '/stat' })}

                    const qs = require('qs');
                    axios.post('add.php', qs.stringify({ 'email': this.getUser.email, 'total':  this.getCount.count_total, 'intuit': this.getPercent }))
                    .then(response => (this.info = response.data));
                    this.$store.dispatch('setCount_0');
                    setTimeout(redir, 1000);
                },

                
                out(){
                  let date=new Date();
                    date.setSeconds(date.getSeconds()-1);
                    document.cookie='salt='+""+"; expires="+date.toGMTString();
                    document.cookie='logi='+""+"; expires="+date.toGMTString();
                  setTimeout(()=>{this.outAll()}, 1000)},
                
                outAll(){this.$store.dispatch('setOut')},
               }
        }
</script>
