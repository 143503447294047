import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      email: null,
      user_in: false
    },

    pro: null,

    count: {count_2_all: 0, count_2_true: 0,
            count_5_all: 0, count_5_true: 0,
            count_10_all: 0, count_10_true: 0,
            count_30_all: 0, count_30_true: 0,
            count_total: 0,
            count_all_true: 0, }
  },
  getters: {
    getCount(state){
      return state.count
    },
    getUser(state){
      return state.user
    },
    getPro(state){
      return state.pro
    }
  },
  mutations: {
    setTrue(state, weight){
      state.count.count_all_true = state.count.count_all_true + weight;
     },
    setCount(state, payload){
       state.count[payload]++;
    },
    setEmail(state, email){
      state.user.email = email;
    },
    setIn(state){
      state.user.user_in = true;
    },
    setOut(state){
      state.user.user_in = false;
      state.user.email = null;
    },
    setPro(state, pro){
      state.pro = pro;
    },
    setCount_0(state)
    { state.count.count_2_all = 0, state.count.count_2_true = 0,
      state.count.count_5_all = 0, state.count.count_5_true = 0,
      state.count.count_10_all = 0, state.count.count_10_true = 0,
      state.count.count_30_all = 0, state.count.count_30_true = 0,
      state.count.count_total = 0,
      state.count.count_all_true = 0 }

  },
  actions: {
    setCount(context, payload){
      context.commit('setCount', payload)
    },

    setTrue(context, weight){
      context.commit('setTrue', weight)
    },

    setEmail(context, email){
      context.commit('setEmail', email)
    },
    setIn(context){
      context.commit('setIn',)
    },
    setOut(context){
      context.commit('setOut',)
    },
    setPro(context, pro){
      context.commit('setPro', pro)
    },
    setCount_0(context){
      context.commit('setCount_0')
    },
    
  }
  
})
