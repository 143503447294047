<template>

  <div class="home">

   <div class="index_block">

      <h2>Угадай одну из двух карт</h2>

      <p class="true">Попыток: {{getCount.count_2_all}} &nbsp;&nbsp;  Угадано: {{getCount.count_2_true}}</p>


      <div class="card_container">

        <img class="card"  :style="{'box-shadow': card.card_0.shadow }" :src="card.card_0.img">

        <div class="cards cards_2">
          <img :id="card.card_1.ind" @click="run" :src="card.card_1.img">
          <img :id="card.card_2.ind" @click="run" :src="card.card_2.img">
        </div>

      </div> 

    </div>
                
  </div>

</template>

<script>

export default {

              data() {
                return {
                 card: {
                    card_0: {
                            img: require('@/assets/img/card_0.png'),
                            ind: 0,
                            shadow: null,
                            },
                    card_1: {
                            img: require('@/assets/img/card_1.png'),
                            ind: 1
                            },
                    card_2: {
                            img: require('../assets/img/card_2.png'),
                            ind: 2
                            } 
                     },
                }
              },

              computed: {
                getCount() {
                  return this.$store.getters.getCount              }
              },
            
              methods: {

            

                run(event){
                  let z = event.target.id;
                  
                  let x = getRandomInRange(1, 2);
                  let dr_0 = () => {this.card.card_0.img = require('@/assets/img/card_0.png');
                                  this.card.card_0.shadow = null}

                  for (var key in this.card){
                    var y = this.card[key].ind

                    if (y==x){
                        
                      this.card.card_0.img = this.card[key].img;
                      this.card.card_0.shadow = '0 0 50px rgba(255, 0, 0, 0.7)'   
                      if (z==x){this.$store.dispatch('setCount', 'count_2_true'); this.$store.dispatch('setTrue', 1); this.card.card_0.shadow = '0 0 50px rgba(0, 255, 0, 0.7)' } 
                      setTimeout(dr_0, 1000);
                      this.$store.dispatch('setCount', 'count_2_all')
                      this.$store.dispatch('setCount', 'count_total')
                      return true;
                    }
                  }

                
                },
              },
            }

            function getRandomInRange(min, max) {
                      return Math.floor(Math.random() * (max - min + 1)) + min;
                      }
</script>